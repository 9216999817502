import { computed, onBeforeMount, onMounted, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ref as refDb, set, onValue, query, orderByChild, limitToLast } from "firebase/database";
import database from "../../../firebase/config";
import getUserAuthenticated from "@/shared/helpers/getUserAuthenticated";
import typesUserStatus from "@/shared/types/typesUserStatus";
import useCalendarAdmin from "@/modules/admin/composables/Calendar/useCalendar";
import useSharedUI from "../useSharedUI";
const usePrivateLayout = () => {
  const store = useStore();
  const router = useRouter();
  const {
    isLogged,
    role,
    token,
    is_new_student,
    completed_info,
    has_random_password,
    timezone
  } = getUserAuthenticated();
  const { toggleModalCalendar } = useCalendarAdmin();
  const { sendNotificationFailPayment , sendNotificationClassesDone , sendNotificationFeedback , sendNotificationClassesStudent , sendNotificationClassesTeacher , sendNotificationRefer , sendingPendingFeedbacksTeacher , sendingPendingFeedbacksTeacherAdmin } = useSharedUI()
  const { getCurrentMenuItems } = useSharedUI();
  const roles = computed(() => store.getters["shared/getListings"].roles);
  const widthScreen = window.innerWidth;

  watchEffect(() => {
    const { completed_info } = store.getters["shared/getUserAuthentication"];
    const body = document?.querySelector('.body-main');
    if(completed_info === false){
      if(widthScreen <= 1201 ){
        body ? body.scrollTo({ top: 700, behavior: 'smooth' }) : '';
      }else if(widthScreen <=  850 ){
        body ? body.scrollTo({ top: 900, behavior: 'smooth' }) : '';
      }
    }else{
      body ? body.scrollTo({ top: 0, behavior: 'smooth' }) : '';
    }
  })
  const setInfoStatus = async () => {
    const studentInfo = computed(
      () => store.getters["students/studentInformation"]
    );

    let info = studentInfo.value;
    if (!info.is_new_student)
      await store.dispatch("students/getTeachersFavorites");

    if (
      info.hasOwnProperty("token_firebase") &&
      info.token_firebase !== undefined && info.token_firebase !== null
    ) {
      const dbRef = refDb(
        database,
        "Spanish/" + info.token_firebase + "/last_status"
      );

      onValue(dbRef, async (snapshot) => {
        let last_status = snapshot.val();
        let status_set;
        if (last_status === "offline") {
          status_set = "online";
        } else {
          status_set = last_status;
        }

        set(
          refDb(database, "Spanish/" + info.token_firebase + "/status"),
          "online"
        );

        store.commit("shared/userAuthentication", {
          status: status_set,
          last_status: last_status,
          token_firebase: info.token_firebase,
        });
      });
    }
  };

  const getNotificationsStudent = async () => {
    const studentInfo = store.getters["students/studentInformation"]

   
    let info = studentInfo;
    if (
      info.hasOwnProperty("token_firebase") &&
      info.token_firebase !== undefined
    ) {
      const dbRef = query(refDb(
        database,
        "notifications/Students/" + info.token_firebase + ""
      ), limitToLast(100));
      onValue(dbRef, async (snapshot) => {
        const { role } = store.getters["shared/getUserAuthentication"]
        console.log(role)
        if(role === "student"){
          if (snapshot.exists())
            store.commit("shared/updateNotificationList", {
              data: snapshot.val(),
            });
          else
            store.commit("shared/updateNotificationList", {
              data: {},
            });
        }
      });
    }
  };

  const getNotificationsTeacher = async () => {
    const teacherInfo = store.getters["teachers/teacherInfo"];
    let info = teacherInfo


    if (
      info.hasOwnProperty("token_firebase") &&
      info.token_firebase !== undefined
    ) {
      const dbRef = query(refDb(
        database,
        "notifications/Teachers/" + info.token_firebase + ""
      ),limitToLast(100));
      onValue(dbRef, async (snapshot) => {
        const { role } = store.getters["shared/getUserAuthentication"]
        if(role === "teacher"){
          if (snapshot.exists())
            store.commit("shared/updateNotificationList", {
              data: snapshot.val(),
            });
          else
            store.commit("shared/updateNotificationList", {
              data: {},
            });
        }
      });
    }
  };

  const getNotificationsCoordinator = async () => {
    const adminInfo = store.getters["admins/adminInfo"];
    let info = adminInfo
    const { role } = store.getters["shared/getUserAuthentication"]


    if (
      info.hasOwnProperty("token_firebase") &&
      info.token_firebase !== undefined
    ) {
      const dbRef = query(refDb(
        database,
        "notifications/Coordinators/" + info.token_firebase + ""
      ), limitToLast(100));
      onValue(dbRef, async (snapshot) => {
        const { role } = store.getters["shared/getUserAuthentication"]
        console.log(role)
        if(role === "coordinator"){
          if (snapshot.exists())
            store.commit("shared/updateNotificationList", {
              data: snapshot.val(),
            });
          else
            store.commit("shared/updateNotificationList", {
              data: {},
            });
       }
      });
    }
  };

  const getNotificationsAdmin = async () => {
    const adminInfo = store.getters["admins/adminInfo"];
    let info = adminInfo;
    const { role } = store.getters["shared/getUserAuthentication"]

    if (
      info.hasOwnProperty("token_firebase") &&
      info.token_firebase !== undefined
    ) {
      const dbRef = query(refDb(
        database,
        "notifications/Admins/" + info.token_firebase + ""
      ), limitToLast(100));
      onValue(dbRef, async (snapshot) => {
        const { role } = store.getters["shared/getUserAuthentication"]
        console.log(role)
        if(role === "admin"){
          if (snapshot.exists())
            store.commit("shared/updateNotificationList", {
              data: snapshot.val(),
            });
          else
            store.commit("shared/updateNotificationList", {
              data: {},
            });
        }
      });
    }
  };

  const setInfoTeacher = () => {
    const teacher_data = computed(() => store.getters["teachers/teacherInfo"]);
    if (
      teacher_data.value.hasOwnProperty("token_firebase") &&
      teacher_data.value.token_firebase !== undefined && teacher_data.value.token_firebase !== null
    ) {
      const dbRef = refDb(
        database,
        "Teachers/" + teacher_data.value.token_firebase + "/last_status"
      );
      onValue(dbRef, async (snapshot) => {
        let last_status = snapshot.val();
        let status_set;
        if (last_status === "offline") {
          status_set = "online";
        } else {
          status_set = last_status;
        }

        set(
          refDb(
            database,
            "Teachers/" + teacher_data.value.token_firebase + "/status"
          ),
          "online"
        );

        store.commit("shared/userAuthentication", {
          status: status_set,
          last_status: last_status,
          token_firebase: teacher_data.value.token_firebase,
        });
      });
    }
  };

  const validPayment = () => {
    const dashboard = computed(
      () => store.getters["students/dashboardStart"]
    );

    let info = dashboard.value.data;
    if(info.isFailPayment){
      sendNotificationFailPayment()
    }
  }

  const validClassesDone = () => {
    const data = computed(
      () => store.getters["admins/teachersClassesDone"]
    );

    let teachersClasses = data.value.data;
    
    teachersClasses.forEach(teacher => {
      if(teacher.total_lessons_done % 100 === 0){
        sendNotificationClassesDone(teacher)
      }
    })
  }

  const validatePendingFeedbacks = () => {
    const { role } = store.getters["shared/getUserAuthentication"];
    if (role === "student") {
      const { data, isLoading } = store.getters["students/pendingFeedbacks"];
      if (!isLoading && data.length > 0 && data[0]) {
        sendNotificationFeedback(data[0]);
      }
    }
  }

  const validateClass = () => {
    const { role } = store.getters["shared/getUserAuthentication"];
    if (role === "student") {
      const { isLoading, totalClasses } =
      store.getters["students/dashboardStart"];
      if (!isLoading && totalClasses > 0) {
        validateClasses(totalClasses);
      }
    }
  }

  const validateClasses = (totalClasses) => {
    const { role } = store.getters["shared/getUserAuthentication"];
    if (role === "student") {
      if (totalClasses % 100 === 0) {
        sendNotificationClassesStudent(totalClasses);
      }
    } else if (role === "teacher") {
      if (totalClasses % 100 === 0) {
        sendNotificationClassesTeacher(totalClasses);
      }
    }
  };

  const validateRefer = (isRefer) => {
    if (isRefer) sendNotificationRefer();
  };


  const validateReferCode = () => {
    const { role } = store.getters["shared/getUserAuthentication"];
    if (role === "student") {
      const { isLoading, data } = store.getters["students/dashboardStart"];
      if (!isLoading) {
        validateRefer(data.remember_to_refer);
      }
    }
  }
  const validatePendingFeedbacksTeacher = () => {
    const { role } = store.getters["shared/getUserAuthentication"];
    if (role === "teacher") {
      const { data, isLoading } = store.getters["teachers/finishClasses"];
      console.log(data)
      if (!isLoading && data.length > 0) {
        sendingPendingFeedbacksTeacher(data);
      }
    }
  }

  const validPendingTeachers = () => {
    const { role } = store.getters["shared/getUserAuthentication"];
    if (role === "coordinator") {
      const { data, isLoading } = store.getters["admins/pendingTeachers"];
      if (
        !isLoading &&
        data.length > 0
      ) {
        sendingPendingFeedbacksTeacherAdmin(data);
      }
    }
  }

  const loadServices = async () => {
    if (isLogged) {
      store.commit("shared/userAuthentication", {
        role,
        token,
        isLogged,
        is_new_student,
        has_random_password,
        completed_info,
        timezone,
        firstProfile: completed_info ? true : false,
      });
      getCurrentMenuItems();
      store.commit("shared/updateNotificationList", {
        data: {},
      });
      if (role === "student") {
        await store.dispatch("students/dashboardStart");
        await store.dispatch("teachers/coordinators");
        await store.dispatch("students/getStudentInfo");
        if(completed_info){
          await store.dispatch("students/classesTakenThisDay")
          await store.dispatch("students/pendingFeedbacks");
          validatePendingFeedbacks()
        }
        await store.dispatch("students/questions");
        setInfoStatus();
        validPayment()
        validateClass()
        validateReferCode()
        getNotificationsStudent();
      } else if (role === "teacher") {
        await store.dispatch("teachers/finishClasses");
        await store.dispatch("teachers/coordinators");
        await store.dispatch("teachers/dashboardStart");
        await store.dispatch("teachers/questionsTeacher");
        setInfoTeacher();
        validatePendingFeedbacksTeacher()
        getNotificationsTeacher();
      } else if (role === "admin") {
        await store.dispatch("admins/emailTemplates")
        await store.dispatch("admins/dashboardStart");
        validPendingTeachers()
        getNotificationsAdmin();
      } else {
        await store.dispatch("admins/emailTemplates")
        await store.dispatch("teachers/coordinators");
        await store.dispatch("admins/dashboardStart");
        await store.dispatch("admins/getTeachersClassesDone")
        validClassesDone()
        await store.dispatch("admins/getPendingFeedbacks")
        getNotificationsCoordinator();
      }

      await store.dispatch("shared/getListings");
    }
  };

  const isCurrentStudentProfile = ref(false);
  watchEffect(() => {

    isCurrentStudentProfile.value =
      router.currentRoute.value.name === "student-profile";
  });

  let userAuthenticated = computed(
    () => store.getters["shared/getUserAuthentication"]
  );

  const status = typesUserStatus;
  
  window.addEventListener("beforeunload",  () => {
    const userAuthenticated = store.getters["shared/getUserAuthentication"];

    if (userAuthenticated.isLogged) {
      if (userAuthenticated.role === "student" && userAuthenticated.token_firebase) {
        set(
          refDb(
            database,
            "Spanish/" + userAuthenticated.token_firebase + "/status"
          ),
          "offline"
        );
      } else if (userAuthenticated.role === "teacher" && userAuthenticated.token_firebase) {
        set(
          refDb(
            database,
            "Teachers/" + userAuthenticated.token_firebase + "/status"
          ),
          "offline"
        );
      } else if (userAuthenticated.role === "admin" && userAuthenticated.token_firebase) {
        set(
          refDb(
            database,
            "Admins/" + userAuthenticated.value.token_firebase + "/status"
          ),
          "offline"
        );
      }
    }
  });

  window.addEventListener("click", (e) => {
    if(e.target){
      if(!e.target.className.includes("notification") && !e.target.className.includes("notification-open")){
        store.commit("shared/updateNotificationList", { isOpen : false });
      }
      if(e.target.className.includes("btn-cancel-calendar")){
        const id = parseInt(e.target.getAttribute("id"));
        const classes = store.getters["students/classesTakenThisWeek"].data
        const classe = classes.find(classe => classe.id === id)
        store.commit("students/cancelClass", { isOpen : true , id , classe  });
      }
      if(e.target.className.includes("btn-detail-classes")){
        const classe_start = e.target.getAttribute("id");
        const classes = store.getters["admins/myCalendar"].data
        const classe = classes.find(classe => classe.lesson_start === classe_start)
        toggleModalCalendar(true,classe);
       }
    }else{
      if(!e.className.includes("notification") && !e.className.includes("notification-open")){
        store.commit("shared/updateNotificationList", { isOpen : false });
      }
    }
  })

  
  return {
    userAuthenticated,
    isCurrentStudentProfile,
    loadServices,
    widthScreen,
    status,
    roles,
  };
};

export default usePrivateLayout;
