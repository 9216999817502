import { ref, watchEffect, computed } from "vue";
import { useStore } from "vuex";
const useCalendar = () => {
  const store = useStore();
  const events = ref([]);

  const loadServices = async () => {
    await store.dispatch("admins/myCalendar");
  };
  const setEvents = (data) => {
    data.forEach((classe) => {
      events.value.push({
        start: classe.lesson_start,
        end: classe.lesson_end,
        title: `<span class="bg-white w-100 px-2" style="border-radius:15px;font-size:12.5px">Total : ${classe.total}</span>
        
        `,
        data: classe,
        content: `
        <div class="d-flex flex-column">
          <span class="px-2" style="font-size:12.5px">${classe.attended} Attended</span>
          <span class="px-2" style="font-size:12.5px">${classe.absent} Absent</span>
          <span class="px-2" style="font-size:12.5px">${classe.canceled} Canceled</span>
        </div>
        <i class="pi pi-book"></i>
        <button id="${classe.lesson_start}"  class="btn-detail-classes mt-1 w-100 btn text-white bg-primary-1 btn-sm">Detail</button> 
        `,
        class: "leisure",
      });
    });
    let hash = {};
    events.value = events.value.filter((event) =>
      hash[event.start] ? false : (hash[event.start] = true)
    );
  };

  const myCalendar = computed(() => {
    return store.getters["admins/myCalendar"];
  });

  const calendarModal = computed(() => {
    return store.getters["admins/toggleModalCalendar"];
  });

  const toggleModalCalendar = (isOpen, data = {}) => {
    store.commit("admins/toggleModalCalendar", { isOpen, data });
  };

  watchEffect(() => {
    let { data } = store.getters["admins/myCalendar"];
    if (data.length > 0) {
      setEvents(data);
    }
  });

  return {
    events,
    toggleModalCalendar,
    calendarModal,
    myCalendar,
    loadServices,
  };
};

export default useCalendar;
