const typesUserStatus ={
    online: {
        class:"bg-feedback-success",
        text:"Online"
    },
    busy: {
        class:"bg-feedback-error",
        text:"Busy"
    },
    absent:{
        class:"bg-feedback-warning",
        text:"Absent"
    },
    offline:{
        class:"bg-neutro-2",
        text:"Offline"
    }
}

export default typesUserStatus
/* 
  STATUS:
  1 - online
  2 - absent
  3 - busy
  4 - offline
*/